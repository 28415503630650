import { computed, makeObservable, runInAction } from 'mobx';

import { moment, TimeFormat } from '@feathr/hooks';
import type { Attributes, ListResponse, TConstraints } from '@feathr/rachis';
import { isWretchError, wretch } from '@feathr/rachis';

import type { IAddress } from '../address';
import { addressConstraints } from '../address';
import type { EmailVerification, EmailVerifications } from '../email_verifications';
import { ECollectionClassName } from '../model';
import { type Template, TemplateClass } from '../templates';
import type { Campaign } from './campaign';
import { EmailBaseCampaign } from './email_base';
import type { IPinpointEmailBaseCampaign } from './types';
import { CampaignClass, CampaignState } from './types';

// Use presenceUnless instead of presence.
const pinpointEmailCampaignAddressConstraints: TConstraints<IPinpointEmailBaseCampaign> =
  Object.keys(addressConstraints).reduce((previousValue, currentValue) => {
    const constraint = addressConstraints[currentValue as keyof IAddress];
    if (constraint) {
      previousValue[`address.${currentValue}`] = constraint;
    }
    return previousValue;
  }, {});

export abstract class PinpointEmailBaseCampaign<
  T extends IPinpointEmailBaseCampaign = IPinpointEmailBaseCampaign,
> extends EmailBaseCampaign<T> {
  public get constraints(): TConstraints<IPinpointEmailBaseCampaign> {
    return {
      ...super.constraints,
      date_send_start: {
        datetime: (
          _: string,
          attributes: Partial<IPinpointEmailBaseCampaign>,
        ): { earliest?: moment.Moment; message: string } | undefined => {
          if (attributes.send_schedule === 'now') {
            return undefined;
          }
          if (
            attributes.state &&
            [CampaignState.Draft, CampaignState.Stopped].includes(attributes.state)
          ) {
            return {
              earliest: moment.utc().add(15, 'minutes'),
              message: `^Start time must be at least 15 minutes in the future`,
            };
          }
          return undefined;
        },
        presence: (
          _: string,
          attributes: Partial<IPinpointEmailBaseCampaign>,
        ): { allowEmpty: boolean; message: string } => {
          return {
            allowEmpty: false,
            message:
              attributes.send_schedule === 'now'
                ? '^Start date cannot be empty'
                : '^Start date/time cannot be empty',
          };
        },
      },
      date_send_end: {
        /*
         * Instead of letting the date picker itself to not allow the user to select a date before the start date,
         * we can enforce it here.
         */
        datetime: (
          value: string,
          attributes: Partial<IPinpointEmailBaseCampaign>,
        ): { earliest?: string; message: string } | undefined => {
          if (attributes.date_send_start) {
            const dateStart = moment.utc(attributes.date_send_start, moment.ISO_8601);
            return {
              earliest: dateStart
                .add(23, 'hours')
                .add(59, 'minutes')
                .format(TimeFormat.isoDateTime),
              message:
                attributes.send_schedule === 'now'
                  ? '^End date must be at least 24 hours after start time'
                  : '^End date/time must be at least 24 hours after start time',
            };
          }
          return undefined;
        },
        presence: (
          _: string,
          attributes: Partial<IPinpointEmailBaseCampaign>,
        ): { allowEmpty: boolean; message: string } => {
          return {
            allowEmpty: false,
            message:
              attributes.send_schedule === 'now'
                ? '^End date cannot be empty'
                : '^End date/time cannot be empty',
          };
        },
      },
      'consent.has_consent': {
        presence: {
          allowEmpty: false,
          message: '^You must provide consent to publish this campaign',
        },
        exclusion: {
          within: [false],
          message: '^You must provide consent to publish this campaign',
        },
      },
      'consent.user': {
        presence: {
          allowEmpty: false,
          message: '^Only a valid user may provide consent',
        },
      },
      'consent.date_consented': {
        datetime: true,
        presence: {
          allowEmpty: false,
          message: '^You must provide a valid group or remove the goal',
        },
      },
      from_address: {
        presence: {
          allowEmpty: false,
          message: '^A from email address must be provided',
        },
        email: {
          message: '^From email address is not valid',
        },
      },
      from_name: {
        presence: {
          allowEmpty: false,
          message: "^From Name can't be blank.",
        },
        format: {
          pattern: "[a-z0-9!#$%&*()\\-+=._?/ '‘]+",
          flags: 'i',
          message:
            "^From Name can only contain alphanumeric characters, and the following symbols: ! # $ % & * ( ) - + = . _ ? / ' . ‘",
        },
      },
      name: {
        exclusion: {
          within: ['Unnamed Single Send Campaign'],
          message: '^Change the default name for this campaign',
        },
        presence: {
          allowEmpty: false,
        },
      },
      segments: {
        presence: {
          allowEmpty: false,
          message: '^Add at least one group',
        },
        array: {
          id: {
            presence: {
              allowEmpty: false,
              message: '^Select an audience for the group',
            },
          },
        },
        inclusion: (...args: any[]) => {
          const { segments } = args[1];

          if (segments.some(({ included }) => included)) {
            return null;
          }

          return { message: '^Add at least one included group' };
        },
      },
      template_id: {
        presence: { allowEmpty: false, message: '^Choose a template' },
      },
      subject: {
        presence: { allowEmpty: false, message: '^The email must have a subject' },
      },
      ...pinpointEmailCampaignAddressConstraints,
    };
  }

  constructor(attributes: Partial<T>) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<T> {
    return {
      ...super.getDefaults(),
      segments: [],
    } as Partial<T>;
  }

  @computed
  public get isPastStartDate(): boolean {
    const sendTime = moment.utc(this.get('date_send_start'), moment.ISO_8601);
    const now = moment.utc();
    return now.isSameOrAfter(sendTime);
  }

  @computed
  public get editable(): boolean {
    const state = this.get('state');

    /*
     * Because of limitations with mobx and subclassing, we cannot override editable
     * in SmartPinpointEmailBaseCampaign.
     */
    if (
      (this.attributes._cls === CampaignClass.AutoPinpointEmail ||
        this.attributes._cls === CampaignClass.SmartPinpointEmail) &&
      state === CampaignState.Stopped
    ) {
      // Prevent editing or re-running smart and auto pinpoint campaigns that are stopped.
      return false;
    }
    return true;
  }

  @computed
  public get readOnly(): boolean {
    const state = this.get('state');
    if (
      [CampaignState.Published, CampaignState.Publishing, CampaignState.Archived].includes(state)
    ) {
      return true;
    }
    return this.isPastStartDate && state !== CampaignState.Draft;
  }

  public get totalViews(): number {
    const stats = this.get('total_stats') || {};
    const flavors = stats.flavors || {};
    return flavors.pinpoint_tracked_email_open || 0;
  }

  public get totalClicks(): number {
    const stats = this.get('total_stats') || {};
    const flavors = stats.flavors || {};
    return flavors.pinpoint_tracked_email_click || 0;
  }

  public async sendTestEmail(request: { email: string[]; per_id: string }): Promise<T> {
    this.assertCollection(this.collection);
    this.assertId(this.id, ECollectionClassName.PinpointEmailBaseCampaign);

    const url = `${BLACKBOX_URL}flight_campaigns/${this.id}/test`;
    const headers = this.collection.getHeaders();
    const response = await wretch<T>(url, {
      headers,
      body: JSON.stringify(request),
      method: 'POST',
    });
    if (isWretchError(response)) {
      runInAction(() => {
        this.isUpdating = false;
        this.isErrored = true;
        this.error = response.error;
      });
      throw response.error;
    } else {
      runInAction(() => {
        this.isUpdating = false;
        this.isErrored = false;
        this.error = null;
      });
    }
    /*
     * TODO: Figure out best way to mimic or call this.collection.processJSONResponse(response)
     * Perhaps move contents of function to collection, and have this be a simple wrapper to it?
     */
    return response.data;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public removeTemplate(templateId: string): void {
    // TODO: filter out by template id when multiple templates can be used

    /*
     * Due to multiple levels of generic inheritance, typescript does not
     * properly recognize template_id as valid, so use any.
     */
    this.set({ template_id: undefined } as Partial<Attributes<this>>);
    this.save();
  }

  public async addTemplate(template: Template): Promise<this> {
    if (!template.id) {
      throw new Error('Template is missing an id');
    }

    const type = template.get('_cls');

    if (type !== TemplateClass.PinpointEmail) {
      throw new Error('Template is not an Email Template');
    }

    /*
     * Due to multiple levels of generic inheritance, typescript does not
     * properly recognize subject or template_id as valid, so use any.
     */
    const campaignSubject = this.get('subject');
    const templateSubject = template.get('subject');
    if (!campaignSubject && !!templateSubject) {
      this.set({ subject: templateSubject } as Partial<Attributes<this>>);
    } else {
      template.set({ subject: campaignSubject });
      await template.patchDirty();
    }
    this.set({ template_id: template.id } as Partial<Attributes<this>>);
    return this.patchDirty();
  }

  public getEmailVerifications(
    EmailVerificationsCollection: EmailVerifications,
  ): ListResponse<EmailVerification> {
    return EmailVerificationsCollection.list({ filters: { email: this.get('from_address') } });
  }

  public getEmailVerification(
    emailVerifications: ListResponse<EmailVerification>,
  ): EmailVerification | undefined {
    return emailVerifications &&
      !emailVerifications.isErrored &&
      emailVerifications.models.length > 0
      ? emailVerifications.models[0]
      : undefined;
  }
}

// Function that allows type narrowing for Pinpoint Email Campaigns.
export function isEmailCampaign(campaign: Campaign): campaign is PinpointEmailBaseCampaign {
  // Difference: includes Drip campaigns
  return campaign.isEmail;
}

// Function that allows type narrowing for Pinpoint Email Campaigns.
export function isPinpointCampaign(campaign: Campaign): campaign is PinpointEmailBaseCampaign {
  return [
    CampaignClass.AutoPinpointEmail,
    CampaignClass.DripStep,
    CampaignClass.PinpointEmail,
    // Difference: includes Partner Message
    CampaignClass.PinpointPartnerMessage,
    CampaignClass.SmartPinpointEmail,
  ].includes(campaign.get('_cls'));
}
// TODO: Why is the logic different between isEmailCampaign and isPinpointCampaign?
