import { ObjectId } from 'bson';
import type { TFunction, WithT } from 'i18next';
import type { IObservableArray } from 'mobx';

import type {
  Account,
  DripCampaign,
  DripStepCampaign,
  Goal,
  ICampaignSegment,
  IDripCampaignStepSpec,
  Segment,
} from '@feathr/blackbox';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import { validateStepGoals } from '../StepGoals';

export function createPlaceholderStep(t: TFunction): IDripCampaignStepSpec {
  return {
    key: new ObjectId().toHexString(),
    name: t('Untitled'),
    is_valid: false,
  };
}

export function validateInitialEnrollment(campaign: DripStepCampaign): ICampaignValidationErrors {
  return campaign.validate(
    [
      'actions',
      'cooldown_value',
      'cooldown_unit',
      'delay_unit',
      'delay_value',
      'repeat',
      'time_to_send',
      'from_address',
      'subject',
      'template_id',
    ],
    false,
    'grouped',
  ).errors;
}

export function validateAutomation(campaign: DripStepCampaign): ICampaignValidationErrors {
  return campaign.validate(
    ['delay_value', 'delay_unit', 'from_address', 'subject', 'template_id', 'set_action'],
    false,
    'grouped',
  ).errors;
}

export function validateDetails(campaign: DripCampaign): ICampaignValidationErrors {
  return campaign.validate(
    ['date_send_start', 'date_send_end', 'consent.has_consent', 'name'],
    false,
    'grouped',
  ).errors;
}

export const validateGroupExclusions = (
  segments: IObservableArray<ICampaignSegment>,
): ICampaignValidationErrors => {
  return {
    exclusions: segments.some((s) => !s.id)
      ? ['Must provide a valid group or remove the exclusion']
      : [],
  };
};

interface IValidationDetails extends WithT {
  account: Account;
  stepOneErrors: string[];
  campaign: DripCampaign;
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  groupExclusions: IObservableArray<ICampaignSegment>;
}

export function validate({
  account,
  campaign,
  stepOneErrors,
  goals,
  goalSegments,
  groupExclusions,
  t,
}: IValidationDetails): ICampaignValidationErrors {
  const stepTwoErrors = validateGroupExclusions(groupExclusions);
  const stepThreeErrors = validateStepGoals(goals, goalSegments);
  const stepFourErrors = validateDetails(campaign);

  const validationErrors: ICampaignValidationErrors = {
    steps: stepOneErrors,
    exclusions: stepTwoErrors.exclusions ?? [],
    goals: stepThreeErrors.goals ?? [],
    name: (stepFourErrors.name as string[]) ?? [],
    date_send_start: (stepFourErrors.date_send_start as string[]) ?? [],
    date_send_end: (stepFourErrors.date_send_end as string[]) ?? [],
    'consent.has_consent': stepFourErrors['consent.has_consent'] ?? [],
  };

  if (account.get('email_health') === 'suspended') {
    validationErrors.account = [
      t('Your Feathr account is suspended and cannot publish any new email campaigns.'),
    ];
  }

  return validationErrors;
}
